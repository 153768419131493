.comment {
    /* background-color: #feeab5; */
    background-color: #96a6ff;
    /* color: #06a306; */
  }
  
  .comment.is-active {
    background-color: #6e83fa;
    /* border-top: 3px solid green;
    border-bottom: 3px solid green; */
  }

  .comment.is-resolved {
    background-color: rgb(255, 255, 255);
  }
  