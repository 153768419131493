/* .suggestion {
    color: #06a306;
    position: relative;
    text-decoration: none;
  }
  .suggestion::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 100%;
    height: 1px;
    background-color: green;
    transform: scaleY(2);
} */
  
  .suggestion.is-active {
    border-top: 3px solid green;
    border-bottom: 3px solid green;
  }
  