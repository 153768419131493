.color-options {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: center;
  gap: 5px;
}

.option,
.hexPreview {
  width: 16px;
  height: 16px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-picker form {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;
}

.color-picker input {
  width: 65%;
  height: 1.3em;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-left: 5px
}

.color-picker button {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.color-picker input:focus {
  outline: none;
}

.popup-wrapper {
  display: inline;
  position: relative;
}

/* ---------------------------------------------- */
.colorPickerBtnPopupWrapper {
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: inline;
  position: relative;
}

.clicked {
  border: 1px solid lightgray;
  border-bottom: none;
}

.popupColor {
  position: absolute;
  top: 25px;
  left: 0;
  /* width: '150px'; */
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(49, 49, 49, 0.8); */
  background: transparent;
  z-index: 5;
  margin: '20px';
}

.colorOptions {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: center;
  gap: 5px;
  margin: '20px';
}