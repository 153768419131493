#document-layout {
  background: var(--background);
  header {
    .nav-pills {
      .nav-link {
        color: #333f52;
        border-radius: 0;
        margin-top: 1px;
        padding: 2px 10px;
      }
      .nav-link:hover {
        background-color: #f1f4f8;
      }
      .nav-link.active,
      .show > .nav-link {
        background-color: #f1f4f8;
        color: #333f52;
      }
      .dropdown-menu {
        border-radius: 2px;
        min-width: 300px;
        box-shadow: 0px 0px 5px #ccc;
        border: none;
        a {
          font-size: 14px;
          padding: 7px 10px;
        }
        a:hover,
        a.active,
        a:active {
          background-color: #f1f4f8;
        }
      }
    }
    input.text-title {
      outline: none !important;
      border-color: white !important;
    }
    input.text-title:focus-visible {
      border-color: var(--bs-border-color) !important;
    }
  }
}

.col-10-custom {
  flex: 0 0 auto;
  width: 61.333333% !important;
}