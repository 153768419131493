.comment-thread-container {
  border-bottom: 1px solid #ced4da !important;
  ;
  border-radius: 0px !important;
}

.comment-thread-container.is-resolved {
  background-color: #f9fafa;
  padding: 0px 10px;
}

.comment-thread-container.is-active {
  background-color: #fafafa !important;
  border-radius: 0px !important;
}

.comment-thread-container>.card-body {
  padding: 0px 10px;
}

.comments-sidebar {
  border: 0;
}

.comments-sidebar>.card-body {
  padding: 16px;
}

.show-replies-btn {
  margin: 16px 0 0 16px;
}