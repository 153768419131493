:root {
  --brandbluebg: #4a48c8;
  --success: #2abf86;
  --danger: #e15554;
  --cauyellow: #e1bc29;
  --BrandBlue: #3d70b2;
  --iconColor: #c5d0de;
  --textDark: #333f52;
  --textDark50: #516173;
  --textDark30: #a8aab7;
  --bglightblue: #f1f4f8;
  --white: #fefefe;
  --box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  --boxShadowBrand: 0px 0px 9px rgba(213, 213, 251, 10) !important;
  --border: #ced4da;
  --colorHover: #d5d5fb;
  --workflowColor: #f1f1f5;
  --bs-accordion-bg: #4a48c8;
}

.nav-cont {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
  background-color: #fefefe;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
}
.avatar ul {
  padding: 0px;
  margin: 0px;
}
.avatar ul li {
  list-style-type: none;
  position: relative;
  cursor: pointer;
}

.avatar ul li:hover {
  content: "";
  display: block;
  background: #d5d5fb;
  left: 0px;
  right: 0px;
  z-index: 1000;
  color: #313131;
}
.avatar ul::after {
  display: table;
  content: "";
  clear: both;
}
.nav-list ul {
  padding: 0px;
  margin: 0px;
}
.nav-list ul li {
  float: left;
  list-style-type: none;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.nav-list ul li:hover {
  color: #313131;
}
.nav-list ul li:hover::after {
  content: "";
  display: block;
  border-bottom: 2px solid black;
  position: absolute;
  left: 15px;
  right: 00px;
  bottom: -3px;
  width: 70%;
}
.nav-list ul::after {
  display: table;
  content: "";
  clear: both;
}
.branding {
  width: 110px;
}
.branding img {
  width: 60px;
  height: 26.25px;
}
.task-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0.5;
}
.task-bar p {
  margin: 0px;
}
.task-bar .avatar {
  position: relative;
}
.task-bar .avatar p {
  padding: 5px 10px;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
}
.hamburger-menu {
  display: none;
}
.hamburger-show {
  display: block;
}
.hamburger-hide {
  display: none;
}
.nav-list-mobile {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
  border-radius: 7px;
  background: white;
}
.nav-list-mobile ul {
  padding: 0px;
  margin: 0px;
}
.nav-list-mobile ul li {
  list-style-type: none;
  padding: 10px;
  margin: 5px;
  border-bottom: 1px solid #1313131f;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .nav-list-mobile {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  .last-login {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .nav-list {
    display: none;
  }
  .task-bar {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
}
.nav-list-mobile {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  z-index: 9999;
  height: 100%;
  overflow-y: hidden;
}
.nav-list-mobile ul {
  /* min-height: 100vh; */
}
.mobile-nav-branding {
  display: flex;
  justify-content: space-between;
}
.settings-modal {
  position: absolute;
  background: #fefefe;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.1);
  top: 55px;
  right: 0px;
  z-index: 3;
  width: 300px;
}
.dropdown-section {
  display: flex;
  flex-direction: row-reverse !important;
  list-style: none;
  width: 200px;
  font-size: 12px;
  line-height: 20px;
  color: #202020;
}
.hamburger-menu-section {
  background-color: white !important;
  font-size: 50px;
}
.img-css {
  display: flex;
}
.Bell-css {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}
.profile-section {
  border-bottom: 1px solid rgba(5, 4, 4, 0.1);
}
.update-button {
  display: flex;
  align-items: center;
  background-color: #4a48c8 !important;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.profile-settings .default-link {
  color: black;
  text-decoration: none;
  background: rgba(32, 32, 32, 0.1);
  padding: 10px;
  border-radius: 3px;
}
.cbtn {
  background: none !important;
  border-radius: 1px !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  padding: 6px 12px !important;
}

.nav-list {
  margin-left: 20%;
  font-weight: 500;
}
.changephoto-css {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  width: 55%;
  color: #4a48c8;
  border: 1px solid #4a48c8;
  padding: 10px;
  border-radius: 10px;
  background-color: #fefefe;
  font-size: small;
}
.profile-css {
  border: 4px solid #fefefe;
  filter: drop-shadow(0px 0px 6px rgba(32, 32, 32, 0.3));
}

.linksinproile {
  text-decoration: none;
  color: #333f52;
  text-decoration: none;
  background: rgba(32, 32, 32, 0.1);
  padding: 15px;
  border-radius: 3px;
}
.members-css {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .members-css {
    flex-direction: column;
  }
}
@media only screen and (max-width: 480px) {
  .profilelinks {
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.actie a {
  color: #516173;
}

.profilelinks .active {
  background: #d5d5fb;
  color: #333f52;
}
.linksinproile:hover {
  color: #333f52;
}
.list-css {
  min-height: 100vh;
}

.avatar_css_members {
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px !important;
  line-height: initial;
  text-align: center;
  color: black !important;
  background: #f1f4f8 !important;
}
.data-table .rdt_TableRow {
  padding-bottom: -59px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.dropbtn {
  color: #313131 !important;
  padding: 0px;
  border: none;
  background-color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;

  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.dropdown-content a {
  color: #313131;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -4px;
  margin-left: 0px;
  border: none;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.dropdown-content a:hover {
  background-color: #d5d5fb !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #ffffff;
}
.rdt_Table {
  min-height: 45vh;
}

// .........................................................accounts........................................................
.nasted_accounts {
  padding-bottom: 0px !important;
}

.nasted_accounts.nav.nav-item.a {
  color: black !important;
}

.nasted_accounts_items {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}

.nasted_accounts_items:hover {
  color: black;
  font-size: 15px;
  font-weight: 500;
}

.nasted_accounts.nav-tabs {
  border-bottom: none !important;
}

.nav_link_admin {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: var(--textDark);
  display: inline-block;
}

.nav_title_admin {
  border-bottom: 3px solid var(--textDark);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 1px solid;
}

span.nasted_accounts_lists {
  border-bottom: 3px solid;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.nasted_accounts_items.nav-link {
  padding: none !important;
  padding-right: 17px;
  padding-left: 2px;
}

.file_no_data_found_admin {
  height: 30vh;
}
