.comment-thread-popover {
  display: none;
  background: rgb(255, 255, 255);
  position: absolute;
  margin-top: 4px;
  /* min-width: 550px; */
  z-index: 1;
}

.comment-list {
  min-height: 300px;
  max-height: 50vh;
  overflow-y: auto;
  position: relative;
}

.comment-row {
  display: flex;
}

.comment-author-name {
  display: block;
  font-weight: bold;
}

.comment-text {
  width: 100%;
}

.comment-author-photo {
  font-size: 24px;
  margin-right: 4px;
}

.comment-creation-time {
  color: #c5d0de;
  font-size: 14px;
}

.comment-input-wrapper {
  border-top: 1px solid #ced4da;
  display: flex;
  padding: 4px;
}

.comment-input {
  border: 0;
}

.comment-input:focus {
  box-shadow: none;
}

.comment-thread-popover-header {
  display: flex;
  justify-content: flex-end;
  min-height: 31px;
}

.suggestion-thread-popover-header {
  display: flex;
  min-height: 31px;
}