.popupLink {
  width: auto;
  position: fixed;
  top: 20px;
  left: 41%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.toastInner {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: '#fefefe';
  border-radius: 15px;
}