// @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@import url("https://fonts.cdnfonts.com/css/arial");

@import url("https://fonts.cdnfonts.com/css/times-new-roman");

@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

:root {
  --brandbluebg: #4a48c8;
  --success: #2abf86;
  --danger: #e15554;
  --cauyellow: #e1bc29;
  --BrandBlue: #3d70b2;
  --iconColor: #c5d0de;
  --textDark: #333f52;
  --textDark50: #516173;
  // --textDark30: #a8aab7;
  --textDark30: #333f52;

  --bglightblue: #f1f4f8;
  --white: #fefefe;
  --box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  --boxShadowBrand: 0px 0px 9px rgba(213, 213, 251, 10) !important;
  --border: #ced4da;
  --colorHover: #d5d5fb;
  --workflowColor: #f1f1f5;
  --bs-accordion-bg: #4a48c8;
}
.textpara {
  font-family: "monospace", sans-serif !important;
}
.textTimesNewRoman {
  font-family: "Times New Roman", sans-serif !important;
}

.textGeorgia {
  font-family: "Georgia", sans-serif !important;
}

.textCalibri {
  font-family: "Calibri";
}

.textFiraSans {
  font-family: "Fira Sans", sans-serif !important;
}

.textArial {
  font-family: "Arial", sans-serif !important;
}

.textSansserif {
  font-family: sans-serif !important;
}
.textOpenSans {
  font-family: "Open Sans", sans-serif !important;
}
.textVerdana {
  font-family: "Verdana";
}
.textpacifico {
  font-family: "Pacifico", cursive;
}
.textTimesCG {
  font-family: "Times CG";
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  * {
    zoom: 0.99999;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.75) {
  * {
    zoom: 0.988;
  }
}
@media (-webkit-min-device-pixel-ratio: 2) {
  * {
    zoom: 0.988;
  }
}
@media (-webkit-min-device-pixel-ratio: 2.5) {
  * {
    zoom: 0.988;
  }
}
@media (-webkit-min-device-pixel-ratio: 3) {
  * {
    zoom: 0.988;
  }
}

body {
  scroll-snap-type: x mandatory;
  scroll-padding: 20px !important;
  overflow-x: hidden;
  // font-family: "Plus Jakarta Sans", sans-serif !important;
  font-family: "Open Sans", sans-serif !important;
  background: rgb(250, 250, 250);
}
.cursor-pointer {
  cursor: pointer;
}
.deleteBtn {
  position: absolute;
  right: -13px;
  top: -10px;
  z-index: 111;
  cursor: pointer;
}
.termsOfUse p {
  line-height: 2 !important;
  padding-bottom: 10px;
}
.termsOfUse p h6 {
  font-size: 14px;
  padding-bottom: 10px;
}
.termOfUsePage {
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  width: 800px;
}
.termsColor {
  color: var(--brandbluebg);
  font-size: 15px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4a48c8;
  --bs-btn-border-color: #4a48c8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3331b9db;
  --bs-btn-hover-border-color: #3331b9db;
  --bs-btn-focus-shadow-rgb: 89, 88, 224;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3331b9db;
  --bs-btn-active-border-color: #2d2ca4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4a48c8;
  --bs-btn-disabled-border-color: #4a48c8;
}
.lowerAlpha::marker {
  font-weight: 300 !important;
  color: #333f52 !important;
}

.signImage {
  width: 100%;
  height: 150px;
  border: 1px solid var(--textDark30);
  border-radius: 3px;
}
.signImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

button {
  border-radius: 1px !important;
}
.form-control {
  border-radius: 1px !important;
}

.nav-disabled {
  color: grey;
}

#avatar-dropdown {
  border: none !important;
}
textarea {
  min-height: 62px !important;
  padding-inline-start: 0.9rem !important;
  padding-top: 0.3rem !important;
  border: 1px solid var(--border) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #a8aab7;
}

.dropdown-toggle::after {
  vertical-align: middle;
}
.filter-z {
  z-index: 1;
}
.dropdownCustom {
  position: absolute;
  top: 45px;
  right: -3px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  // width: max-content;
  min-width: 80vh;
  box-shadow: var(--box-shadow);
}

.filterdpr .css-13cymwt-control {
  height: 40px !important;
}

.dropdownCustom.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}
.dropdownCustom.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
  cursor: pointer;
}

.dropdownFilter {
  position: absolute;
  top: 45px;
  right: -3px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  // width: max-content;
  min-width: 80vh;
  box-shadow: var(--box-shadow);
  // z-index: 999;
}
.barchstr svg {
  height: 410px !important;
}

.dropdownFilter.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}
.dropdownFilter.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
  cursor: pointer;
}
.dropdownsortMobile {
  position: fixed;
  bottom: 60px;
  left: 0;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  box-shadow: var(--box-shadow);
  // z-index: 999;
  cursor: pointer;
}

.dropdownsortMobile.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(0px);
  // transition: 500ms ease;
  cursor: pointer;
}

.dropdownsortMobile.inactive {
  opacity: 1;
  visibility: hidden;
  // transform: translateY(-20px);
  // transition: 500ms ease;
  cursor: pointer;
}

.customAnalyticsbtn {
  border: 1px solid var(--border);
  cursor: pointer;
  text-align: center;
  min-width: 160px;
}
.customAnalyticsbtn:hover {
  background-color: #ecebfb;
  color: var(--brandbluebg);
}
.customAnalyticsbtn.active {
  background-color: #ecebfb;
  color: var(--brandbluebg);
}
.customizebtn {
  border: 1px solid var(--border);
  cursor: pointer;
  color: var(--textDark30);
}
.customizebtn:hover {
  border: 1px solid var(--brandbluebg);
  color: var(--brandbluebg);
}
.customizebtn.active {
  border: 1px solid var(--brandbluebg);
  color: var(--brandbluebg);
}
.dropdowncustomMobileNormal {
  bottom: 60px;
}
.dropdowncustomMobileAnalytics {
  bottom: 40px;
}

.dropdowncustomMobile {
  position: absolute;
  left: 0;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 83vh;
  overflow-y: scroll;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  // z-index: 999;
}

.dropdowncustomMobile.active {
  opacity: 1;
  visibility: visible;
  // transform: translateY(0px);
  // transition: 500ms ease;
  cursor: pointer;
}

.dropdowncustomMobile.inactive {
  opacity: 1;
  visibility: hidden;
  // transform: translateY(-20px);
  // transition: 500ms ease;
  cursor: pointer;
}
.multi-range-slider {
  box-shadow: none !important;
  border: none !important;
  padding: 20px 10px !important;
}
.multi-range-slider .thumb::before {
  box-shadow: none !important;
  border: none !important;
}
.multi-range-slider .ruler .ruler-rule {
  border: none !important;
  padding: 0px !important;
}
.multi-range-slider .bar-right {
  box-shadow: none !important;
}
.multi-range-slider .bar-left {
  box-shadow: none !important;
}
.multi-range-slider .bar-inner {
  box-shadow: none !important;
  border: none !important;
}
.multi-range-slider .thumb .caption * {
  background-color: var(--BrandBlue) !important;
  box-shadow: var(--box-shadow) !important;
}

// .............................templates..........................................

.filter-css {
  border: none !important;
  background-color: #fefefe !important;
  color: var(--textDark30) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 20px;
  padding-right: 14px;
  height: 40px;
  // margin-left: 20px;
  box-shadow: var(--box-shadow);
}
.pbtn {
  background-color: transparent !important;
  color: var(--textDark30) !important;
  border: none !important;
  font-weight: 700;
}

.mobile-filter-css {
  border: none !important;
  border-radius: 3px !important;
  box-shadow: var(--box-shadow);
  opacity: 1 !important;
  background-color: #fefefe !important;
  color: var(--textDark30) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  height: 40px;
  border-radius: 3px !important;
}

.create-template {
  display: flex;
  color: white;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  font-weight: 600;
}
@media screen and (max-width: 540px) {
  .create-template {
    font-size: 14px;
    height: 40px;
  }
  .contract-css {
    font-size: 12px !important;
  }
  .search-css {
    height: 40px !important;
  }
  .search-icon-css {
    margin-top: 13px !important;
  }
  .search-css .search-input {
    font-size: 14px;
    font-weight: 600;
  }
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #d5d5fb;
  outline: 0;
  box-shadow: 0 0px 9px #d5d5fb !important;
}

.contract-division {
  box-shadow: var(--box-shadow) !important;
  background-color: white !important;
  border-radius: 0.1rem;
  min-height: 231px;
}
.contract-division:hover {
  box-shadow: 0 0px 9px rgba(41, 77, 235, 0.4) !important;
}

.isActiveCard {
  box-shadow: 0 0px 9px rgba(41, 77, 235, 0.4) !important;
  // box-shadow: var(boxShadowBrand) !important;
}

.recent-templete-skeleton {
  margin-left: 5px;
  // z-index: -1;
  margin-right: 15px;
  margin-top: 15px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(204, 25, 25, 0);
}

::-webkit-scrollbar-thumb {
  // background: #a8aab730;
  background: #3d70b287;
  cursor: grabbing !important;

  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(248, 241, 241, 0);
}

.search-css {
  height: 40px;
  border: none;
  padding-left: 0px;
  width: auto;
  border-radius: 0.1rem;
  box-shadow: var(--box-shadow);
  background: #ffffff;
}
.input-border {
  // border: 1px solid lightgray;
}
.search-icon-css {
  margin-top: 14px;
  font-size: 16px;
  color: var(--textDark30);
  margin-left: 19px;
  cursor: pointer;
}

.search-css .search-input {
  border-radius: 0.1rem;
}
::placeholder {
  color: var(--textDark30) !important;
  font-size: 14px !important;
}
.select__placeholder {
  font-size: 14px !important;
}
.select__single-value {
  font-size: 14px !important;
}
.select__menu {
  font-size: 14px !important;
}

.dropdown-toggle::after {
  border: none !important;
}

.sort-filter-mobile {
  width: 100%;
  background-color: #fefefe;
  box-shadow: var(--box-shadow);
  padding: 20px 0px;
  position: fixed;
  bottom: 0px;
}

.dropdownsort {
  position: absolute;
  top: 45px;
  left: 25px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 170px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}
.dropdownsort.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
  cursor: pointer;
}
.dropdownsort.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
  cursor: pointer;
}

.sort-by {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  color: var(--textDark);
}
.sort-by:hover {
  font-weight: 600;
}
.sort-by-tablet {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  color: var(--textDark);
}

.sort-by-dropdown .css-1nmdiq5-menu {
  width: 160px;
}

.sort-by-dropdown .css-13cymwt-control {
  height: 50px !important;
  border: none !important;
}

.filter-dropdown .dropdown-menu.show {
  min-width: 301px !important;
  padding: 15px !important;
  width: 100%;
  z-index: 1;
}

//..............................................filter css...............................................
.status-title {
  margin-left: 0px;
  color: var(--textDark50);
  margin-bottom: 2px;
  font-size: 14px;
}
label {
  font-size: 14px !important;
}
.active-status {
  border: 1px solid lightgray;
  color: var(--textDark30);
  margin-top: 10px;
  width: 100%;
}

.active-status:hover {
  background-color: #4a48c8;
  color: white !important;
}

.sort-dropdown-css.dropdown-menu {
  margin-top: 5px !important;
  width: 100% !important;
  border: none !important;
  padding: 0px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
}
.create-dropdown-css.dropdown-menu {
  margin-top: 5px !important;
  border: none !important;
  padding: 0px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
}

.sort-dropdown-css.dropdown-menu:active {
  background-color: #f1f4f8 !important;
}

// ................................................filter by...........................................
.filter-dropdown-menu {
  padding: 15px;
  border: none;
  margin-top: 5px;
}

.filter-body-mobile {
  position: absolute;
  bottom: 50px;
  background-color: white;
  width: 100%;
  padding: 15px;
}
.filter-body-mobile:active {
  position: absolute;
  bottom: 50px;
  background-color: white;
  width: 100%;
  padding: 15px;
  transform: translateY(0px);
  transition: 500ms ease;
}

.sort-by-title {
  font-weight: 700;
  border-bottom: 1px solid #cccccc;
  font-size: 16px;
  color: #000000d3;
}

.mobile-filter {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 15px;
  bottom: 0;
  margin-top: 10px;
  padding-left: 12px;
}

.close-filter {
  color: red;
  cursor: pointer;
}

.sort-filter-moblie-close {
  width: 100%;
  background-color: #fefefe;
  box-shadow: var(--box-shadow);
  padding: 20px;
  position: fixed;
  bottom: 0px;
  border-top: 1px solid lightgray;
  background-color: #f9f9f9;
}

// ............................templates card section.......................................
.contract-card {
  background-color: lightgray !important;
  width: "40px";
  height: "40px";
  margin-right: 15px;
}

.contractor-name {
  font-weight: 500;
  margin-top: 3px;
}

.card-contract-name {
  font-weight: 700;
  font-size: 14px;
}

.card-signature {
  color: var(--textDark30) !important;
  opacity: 0.7;
  letter-spacing: 0.5;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  color: #1c2024;
}

.card-clock {
  color: lightgray;
  font-size: 19px;
}

.updated-on-css {
  margin-top: 10px;
}
.contract-team-name {
  font-size: 12px;
}
.contract-team-names {
  font-size: 12px;
}
.pro-name {
  background-color: var(--workflowColor);
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.pro-names {
  font-size: 12px;
  font-weight: 700;
  color: #4a48c8;
}

.profileAvatar {
  width: 25px;
  height: 25px;
  color: var(--textDark30);
  margin-top: -3px;
  border-radius: 50%;
  padding: 3px;
}

.profile-avatars {
  border-radius: 50%;
  margin-right: -9px;
}
.avatarCss.sb-avatar__text {
  background-color: #4a48c8 !important;
  color: white;
  border-radius: 50%;
}
.avatarIMG {
  border-radius: 50%;
}
.profile-avatars.sb-avatar__text {
  border-radius: 50%;
  background: var(--brandbluebg) !important;
  color: var(--white) !important;
  font-weight: 400 !important;
  border: 2px solid #fefefe;
  box-shadow: var(--box-shadow);
}

.template-css {
  width: 250px;
  cursor: pointer;
}
.passwordIconHide .was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}
.contract-title {
  font-weight: 600;
  font-size: 14px;
  cursor: default;
  color: var(--textDark);
  display: inline-block;
  border-bottom: 3px solid var(--textDark);
}

.template-width {
  max-width: 300px;
}
.Category-width {
  max-width: 300px;
}
.recent-temp {
  min-width: 260px;
  flex-grow: 1;
}
@media screen and (max-width: 540px) {
  .template-width {
    max-width: 100%;
  }
  .recent-temp {
    min-width: 100%;
    flex-grow: 1;
  }
  .contract-division {
    margin: 0px 12px;
  }
}

.recent-temp-div {
  overflow: scroll;
}

.navbar-con {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.nav-border {
  border-bottom: 1px solid lightgray;
}

.nav-text-css {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: #1c2024;
}

.link-css {
  text-decoration: none !important;
  color: var(--textDark) !important;
  font-weight: 600;
  font-size: 12px !important;
}
.category-navbar-css .nav-border {
  margin-bottom: 20px;
}

.nav-text-active {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: var(--textDark) !important;
  border-bottom: 3px solid var(--textDark);
}

.contract-card-transfer {
  background-color: white !important;
  opacity: 2;
  width: "40px";
  height: "40px";
  margin-right: 15px;
  height: 240px;
  margin: 5px;
}

.card-content {
  opacity: 0.70089;
  color: rgba(49, 49, 49, 1);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 25px;
  padding-top: 12px;
  padding-left: 20px;
}

.agreement-box {
  box-shadow: var(--box-shadow) !important;
  opacity: 1;
  background-color: rgba(254, 254, 254, 1);
  padding: 12px;
  border-radius: 1px 1px 0px 0px;
}

.rdt_Pagination {
  border-radius: 0px 0px 1px 1px;
  // box-shadow: var(--box-shadow) !important;
}

.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin-left: 7px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.btn_loader {
  width: 20px;
  height: 20px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin-left: 7px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.select-wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
}

.basic-multi-select .css-fz8l8n-option {
  background-color: #f1f4f8;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #1c2024;
}
.form-check-input[type="checkbox"] {
  cursor: pointer !important;
}
.share_folder {
  margin-left: 15px;
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #4a48c8 !important;
}
// data not found
.File404 {
  display: flex;
  justify-content: center;
  height: 52vh;
  overflow: hidden;
  margin-top: 20px;
}

.font404 {
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: black;
  text-transform: uppercase;
  letter-spacing: -40px;
}
.font404eorr {
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: var(--text-black);
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -55px;
  direction: ltr !important;
}

.category-navbar-css {
  min-width: 700px;
}

.no-data-found {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.breadcrumbs-nodata {
  border-bottom: 2px solid black;
}

.contract-navbar {
  z-index: -1;
}
.filter-body-mobile {
  z-index: 9999;
}

.mobile-filter {
  z-index: 9999;
}
.data-table .rdt_TableHeadRow {
  justify-content: flex-start;
}
.data-table .rdt_TableRow {
  justify-content: flex-start;
}
.hXLGuA {
  min-height: 480px !important;
}
.add-template .btn-default {
  border: none !important;
  padding: 0px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.add-template.dropdown-menu {
  width: 300px !important;
}

.btn-add-template {
  background-color: white !important;
  border: 1px solid green !important;
  color: green !important;
}

.manage-data {
  font-weight: 500;
  padding-right: 13px;
}

.text-add-template {
  font-size: 10px;
  font-weight: 400;
}

// ................................................table css..............................
.sc-jsMahE {
  cursor: pointer !important;
}
.jICeoa {
  display: none !important;
}
.fVGwTk {
  display: none !important;
}
.ewhdCu {
  // min-height: 400px !important;
}
.data-table {
  font-size: 18px;
  font-weight: 600;
  box-shadow: var(--box-shadow) !important;
  min-height: 45vh !important;
}

.rdt_TableCell input {
  display: none !important;
}

.rdt_TableCol input {
  display: none !important;
}
.data-table .rdt_TableRow {
  border-bottom: 1px solid #0000000d !important;
}
.rdt_TableRow:hover {
  background-color: #d5d5fb5c !important;
}

.rdt_TableHeadRow {
  font-size: 16px;
}

.status-badge {
  border-radius: 0.1rem;
  opacity: 1;
  background-color: #f1f4f8 !important;
  opacity: 1;
  color: #000000de !important;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.focus-input {
  outline: none !important;
}
.search-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffffff !important;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}

.footer {
  opacity: 1;
  color: rgba(49, 49, 49, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
}

// .card-width {
//   width: 260px;
// }

.clock-style {
  color: lightgray;
  font-size: 17px;
  padding: 0px;
  margin-top: 2px;
  margin-right: 5px;
}

.contract-css {
  display: flex;
  align-items: center;
  color: white !important;
  font-size: 14px;
  // box-shadow: var(--box-shadow) !important;
}
.bg-trans-dropdown {
  background: transparent !important;
  color: var(--brandbluebg) !important;
}

.icon-fa {
  cursor: pointer;
}

.search-input {
  border: none;
}

// ....................................profile section............................................................
.checkbox_upload_img {
  margin-bottom: 39px;
  margin-right: 10px;
}
.menuItemsSideBar {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
}
.SideBarBg {
  background: var(--brandbluebg);
  max-width: 220px;
  min-width: 220px;
  min-height: 100vh;
}

// .content-main {
//   height: 50vh;
//   overflow-y: scroll;
//   background-color: #fafafa;
// }
.menu-item {
  color: white;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
.menu-item:hover {
  color: #f1f4f8;
  font-weight: 500;
  li {
    background-color: #5957dfbf;
  }
}
.nav-active {
  color: #f1f4f8;
  font-weight: 500;
  li {
    background-color: #5957dfbf;
  }
}
.header {
  background: var(--white);
  position: fixed;
  top: 0;
  z-index: 111;
  width: 100%;
}
.headerpopup {
  z-index: 2222;
}

#avatar-dropdown {
  border: none !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #a8aab7;
}
.dropdown-toggle::after {
  vertical-align: middle;
}

.nav {
  display: flex;
  text-decoration: none;
  color: #4a48c8;
  position: relative;
  font-weight: 500 !important;
}

.active-profile-nav:hover {
  border-bottom: 2px solid #4a48c8;
}
.field-error {
  color: red;
}
.active-profile-nav.active {
  border-bottom: 2px solid #4a48c8;
  font-weight: 700;
  font-size: 15px;
}

.phone-input-css {
  border: 1px solid #ced4da;
  padding: 6px;
  border-radius: 3px !important;
}
.PhoneInputInput {
  border: none !important;
}
.font {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333f52;
  background-color: #fefefe;
  width: 30px;
  height: 30px;
  box-shadow: 10px 24px 54px rgba(5, 5, 6, 0.2);
  border-radius: 50%;
}
.circle {
  display: flex;
  border: none;
}
.profile {
  box-shadow: 10px 24px 54px rgb(15 13 35 / 3%) !important;
  padding: 10px;
}
.update-btn {
  display: flex;
  justify-content: flex-end;
  background-color: #2abf86 !important;
  color: white;
  border: 1px solid #f1f4f8;
}
.update-btn:hover {
  border: 1px solid #f1f4f8;
  color: white;
  background-color: #f1f4f8;
}
.close-btn-css {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  padding: 8px;
}
.cbtn {
  height: 47px;
  border: 1px solid #e15554;
  color: #e15554;
  background-color: white;
}
.cbtn:hover {
  border: 1px solid #e15554;
  color: #e15554;
  background-color: white;
}
.cbtns {
  border: 1px solid #e15554;
  color: #e15554;
  background-color: white;
}
.cbtns:hover {
  border: 1px solid #e15554;
  color: #e15554;
  background-color: white;
}
.p-header {
  display: flex;
  flex: row;
}

.profile-username {
  opacity: 1;
  color: rgba(49, 49, 49, 1);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.profile-pic {
  width: 200px;
  max-height: 200px;
}

.file-upload {
  display: none;
}

.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

img {
  max-width: 100%;
  height: auto;
}

.p-image {
  top: 63px;
  right: 5px;
  color: #666666;
  margin-right: 7px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

// ........................................................create contract from template popup.....................................
.share-btn {
  width: max-content;
  background: #2abf86 !important;
  border-radius: 1px !important;
  border: none !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.share-hallowbtn {
  width: max-content;
  background: var(--brandbluebg) !important;
  border-radius: 1px !important;
  border: none !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.cancel-btn {
  background: none !important;
  border-radius: 1px !important;
  border: 1px solid #e15554 !important;
  color: #e15554 !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.cancel-hallowbtn {
  background: none !important;
  border-radius: 1px !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  padding: 6px 12px !important;
}

.no-template-found {
  margin-top: 70px !important;
  font-size: 21px;
  color: cornflowerblue;
}

.create-cont-from-temp {
  font-size: 16px;
}

// .create-contract-popup-filter .filter-css {
//   border: 1px solid lightgray !important;
// }

.create-contract-popup-filter .filter-dropdown-menu {
  // box-shadow: 10px 24px 54px rgb(15 13 35 / 0.3) !important;
  box-shadow: var(--box-shadow) !important;
}

.create-contract-popup-filter .sort-dropdown-css {
  // box-shadow: 10px 24px 54px rgb(15 13 35 / 0.3) !important;
  box-shadow: var(--box-shadow) !important;
}

.card-active {
  background-color: lightgray !important;
}

.is-create-contract-popup {
  box-shadow: var(--box-shadow) !important;
}

// ....................................... pagination css...........................
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paginate-ctn {
  display: flex;
}
.round-effect {
  color: #2a27ed;
  cursor: pointer;
  border: 1px solid #2a27ed;
  font-size: 16px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  border-radius: 15px;
  height: 40px;
  width: 40px;
  margin: 5px;
  text-decoration: none;
}
.round-effect:hover {
  text-decoration: none;
  background: #2a27ed;
  color: white;
}
.round-effect:hover a {
  text-decoration: none;
  color: white;
}

.select__option--is-selected.css-1soc0q2-option {
  background-color: #f1f4f8;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #1c2024;
}

.filter-dropdown .filter-dropdown-menu {
  box-shadow: var(--box-shadow);
}
.sort-by-filter .sort-dropdown-css,
.sort-by-filters .sort-dropdown-css {
  box-shadow: var(--box-shadow);
}
.dropdown-menu.show {
  box-shadow: var(--box-shadow) !important;
  border-radius: 1px !important;
  padding: 5px !important;
  margin-top: 5px !important;
}
// .upload-template-css {
//   width: 233px;
// }

.add-template.box-shadow {
  box-shadow: var(--box-shadow);
  border: none !important;
}

.min-allowes {
  opacity: 0.696628;
  color: rgba(32, 32, 32, 1);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

// ................................template design..................................
.publish-badge {
  background-color: rgb(61, 112, 178, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #3d70b2;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.Stamp_initiated_baddge {
  background-color: rgb(145, 127, 179, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #917fb3;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.Stamp_failed_badge {
  background-color: rgb(145, 127, 179, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #917fb3;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.pending_badge {
  background-color: rgb(196, 98, 0, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #c46200;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.draft-badge {
  border-radius: 0.1rem;
  opacity: 1;
  background-color: #f1f4f8 !important;
  opacity: 1;
  color: #000000de !important;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.negotiation_bar .progress-bar {
  background-color: #fad800 !important;
  border-radius: 20px !important;
}
.progress-bar {
  background-color: #3d70b2 !important;
  border-radius: 20px !important;
}
.stamp_initiated_bar .progress-bar {
  background-color: #917fb3 !important;
  border-radius: 20px !important;
}

.rejected_bar .progress-bar {
  background-color: #e1bc29 !important;
  border-radius: 20px !important;
}
.stamp_bar .progress-bar {
  background-color: #2c29ee !important;
  border-radius: 20px !important;
}
.draft_bar .progress-bar {
  background-color: #f1f4f8 !important;
}
.pending_approval_bar .progress-bar {
  background-color: #e15554 !important;
}
.pending_bar .progress-bar {
  background-color: #c46200 !important;
}
.approval .progress-bar {
  background-color: rgb(225, 188, 41, 0.1) !important;
}
.sign_bar .progress-bar {
  background-color: #f96204 !important;
}
.complete_bar .progress-bar {
  background-color: #2abf86 !important;
}

.complete-badge {
  background-color: rgb(42, 191, 134, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #2abf86;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.stamped_badge {
  background-color: rgb(44, 41, 238, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #2c29ee;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.signinitiated-badge {
  background-color: rgb(249, 98, 4, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #f96204;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.pendingapproval-badge {
  background-color: rgb(225, 85, 84, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #e15554;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.pending_negotiation_badge {
  background-color: rgb(250, 216, 0, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #fad800;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}
.approved-badge {
  background-color: rgb(225, 188, 41, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #e1bc29;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.rejected_badge {
  background-color: rgb(225, 188, 41, 0.1) !important;
  border-radius: 0.1rem;
  opacity: 1;
  opacity: 1;
  color: #e1bc29;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.draft-badge-bar {
  height: 5px;
  background-color: #000000de !important ;
  width: 200px;
}
.publish-badge-bar {
  height: 5px;
  background-color: #3d70b2 !important ;
  width: 200px;
}

.pendingapproval-badge-bar {
  height: 5px;
  background-color: #e15554 !important ;
  width: 200px;
}
.approved-badge-bar {
  height: 5px;
  background-color: #e1bc29 !important ;
  width: 200px;
}
.signinitiated-badge-bar {
  height: 5px;
  background-color: #596204 !important ;
  width: 200px;
}
.complete-badge-bar {
  height: 5px;
  background-color: #2abf86 !important ;
  width: 200px;
}

// ..................................delete toast................................
.nobtn {
  padding: 15px 20px;
  background: white;
  border: 1px solid var(--brandbluebg);
  color: var(--brandbluebg);
  display: inline-block;
  border-radius: 1px;
  font-weight: 600;
  cursor: pointer;
}

.nobtn:hover {
  padding: 15px 20px;
  background: white;
  border: 1px solid var(--brandbluebg);
  color: var(--brandbluebg);
  display: inline-block;
  border-radius: 1px;
  font-weight: 600;
  cursor: pointer;
}

.yesbtn {
  padding: 15px 20px;
  background: var(--brandbluebg);
  border: 1px solid var(--brandbluebg);
  color: #fefefe;
  display: inline-block;
  border-radius: 1px;
  font-weight: 600;
  cursor: pointer;
}

// .rdt_TableHeadRow,
// .rdt_TableRow {
//   margin-left: -30px;
// }

.footer {
  opacity: 1;
  color: rgba(49, 49, 49, 1);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
}

.clock-style {
  color: lightgray;
  font-size: 17px;
  padding: 0px;
  margin-top: 2px;
  margin-right: 5px;
}

.icon-fa {
  cursor: pointer;
}

.search-input {
  border: none;
}

.sort-filters {
  z-index: 1111;
}
.overviewtext {
  font-size: 14px;
  font-weight: 600;
  color: #313131;
}
.overviewtext:hover {
  font-size: 14px;
  font-weight: 600;
  color: #3d70b2;
}
.overviewtext:focus {
  font-size: 14px;
  font-weight: 600;
  color: #3d70b2;
}
.overviewtext:active {
  font-size: 14px;
  font-weight: 600;
  color: #3d70b2;
}
.savedbtn {
  width: max-content;
  background: #c5f4ec !important;
  // background: transparent !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #33e4b1 !important;
  padding: 8px 30px !important;
  font-size: 16px !important;
}
.variablebtn {
  width: max-content;
  background: transparent !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: var(--brandbluebg) !important;
  padding: 8px 11px !important;
  font-size: 16px !important;
}
.addbtn {
  width: max-content;
  background: #33e4b1 !important;
  border-radius: 5px !important;
  border: 1px solid #33e4b1 !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #fefefe !important;
  font-size: 16px !important;
}
.cancelbtn {
  width: max-content;
  background: transparent !important;
  border-radius: 1px !important;
  border: 1px solid var(--brandbluebg) !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: var(--brandbluebg) !important;
  font-size: 16px !important;
}
.resolvebtn {
  width: max-content;
  background: transparent !important;
  border-radius: 1px !important;
  border: 0px solid var(--brandbluebg) !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: var(--brandbluebg) !important;
  font-size: 16px !important;
}
.resolve .btn:disabled {
  border-color: transparent !important;
}
.inprogress {
  color: #ffbb00;
}
.forProgresscolor {
  background: #fefefe;
  height: 80dvh;
  overflow: scroll;
}
// .overViewRight {
//   background: #fafafa;
//   max-height: 60dvh;
//   overflow: scroll;
// }
.forProgress {
  border-left: 1px solid #b1b1b1;
}
.PositionImg {
  // position: absolute;
  // top: -1px;
  // left: -25px;
  width: 100%;
}
.PositionImgFor {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  // box-shadow: 10px 24px 54px rgba(83, 83, 85, 0.2);
}
.Commenter {
  font-size: 14px;
}
.NDA {
  font-weight: 900;
}
.linedivider {
  height: 1px;
  border-bottom: 1px solid #b1b1b1;
}
.RECEIVING {
  text-align: start !important;
}

.btn_radius {
  border-radius: 3px !important;
}
.SignText {
  font-size: 14px;
  font-weight: 600;
}
.edit_link_routes {
  text-decoration: none;
  color: black;
  position: relative;
  width: max-content;
  font-weight: 500;
}

.edit_link_routes:hover {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.edit_link_routes.active {
  color: black !important;
  font-weight: 500 !important;
}

.edit_link_routes.active:after {
  width: 100%;
  left: 0;
}

.edit_link_routes:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -15px;
  margin-bottom: 10px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  // color: var(--bg-white);
}

.edit_link_routes:focus:after {
  width: 100%;
  left: 0;
}

.edit_link_routes:hover:after {
  width: 100%;
  left: 0;
}
.active-content {
  display: block;
  box-shadow: var(--box-shadow);
  margin-bottom: 20px;
}
@media only screen and (max-width: 540px) {
  .active-content {
    margin: 10px;
  }
}

.content {
  display: none;
}
.nestedul {
  border-bottom: 1px solid #b1b1b1;
}
.sideButtons {
  position: absolute;
  bottom: 10%;
  right: 40px;
}
.sideButtonsBg {
  background: #fefefe;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 15px;
}
// --------------------------------------------------------------------------------------activity feed----------------------------------

.activityFeedDetails {
  box-shadow: var(--box-shadow);
  max-height: 60vh;
  overflow-y: scroll;
}

.actfeedheight {
  overflow: scroll;
}
.activityfeed {
  border-left: 1px solid var(--brandbluebg) !important;
}
.activityfeedInActive {
  border-left: 1px solid var(--textDark30) !important;
}
.sideboxInActive {
  width: 20px;
  height: 20px;
  background: var(--textDark30);
  border-radius: 5px;
  left: -11px;
}
.sidebox {
  width: 20px;
  height: 20px;
  background: var(--brandbluebg);
  border-radius: 5px;
  left: -11px;
}

.datefeed {
  font-size: 14px;
  color: var(--textDark30);
}
.activity_details {
  font-size: 16px;
}

.ticketreminder {
  color: red;
}

.nestedul {
  max-width: 600px;
}

.iconforbillboardform {
  padding-inline-start: 0.7rem;
  padding-inline-end: 0.7rem;
}

.errorArabic {
  direction: ltr;
}

.pointer {
  cursor: pointer !important;
}
.fordashboard .dropdown-toggle {
  border-color: #bdc1c6 !important;
}

.fordashboard .dropdown-toggle {
  border-radius: 5px !important;
  color: black !important;
  border-radius: 5px !important;
  padding: 3px 10px !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
}
.fordashboard .btn.show {
  color: black !important;
  background-color: transparent !important;
  border-color: #bdc1c6 !important;
}

.fordashboard .btn:hover {
  color: black !important;
  background-color: transparent !important;
  border-color: #bdc1c6 !important;
}
.fordashboard .dropdown-toggle::after {
  border: none !important;
}

#doc_searchQueryInput {
  box-shadow: var(--box-shadow);
  background: #fefefe;
  border-radius: 5px;
  height: 2.8rem;
  border-radius: 5px;
  outline: none;
  border: none;
  // border-radius: 1.625rem;
  // padding: 0 3.5rem 0 3.5rem;
  padding-inline-start: 0.7rem;
  margin-inline-start: 5px;
  font-size: 1rem;
  width: 100%;
  // background-image: url("../src/assets/search.png");
  // background-repeat: no-repeat;
  // background-size: 24px;
  // background-position: 1000px;
}
.btnAdd {
  // min-width: 129px !important;
  width: max-content;
  background: var(--success) !important;
  border-radius: 5px !important;
  border: none !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
.Variables {
  background: var(--white);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
}
.rmdp-container {
  width: 100% !important;
}
.rmdp-input {
  width: 100% !important;
  padding: 6px 12px !important;
  min-height: 38px !important;
}
.variablesoverflow {
  overflow-y: scroll;
}
.fordesign .dropdown-toggle {
  border-color: var(--border) !important;
  border-radius: 3px !important;
  color: var(--textDark) !important;
  border-radius: 5px !important;
  padding: 6px 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
}
.fordesign .btn.show {
  color: var(--textDark) !important;
  background-color: transparent !important;
  border-color: var(--border) !important;
  border-radius: 3px !important;
}

.fordesign .btn:hover {
  color: var(--brandbluebg) !important;
  background-color: transparent !important;
  border-radius: 3px !important;
  border-color: var(--brandbluebg) !important;
}
.fordesign .dropdown-menu.show {
  width: 100%;
}

.fordesign .dropdown-toggle::after {
  display: inline-block !important;
  margin-left: 0.255em !important;
  vertical-align: 0.255em !important;
  content: "" !important;
  border-top: 0.3em solid !important;
  border-right: 0.3em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0.3em solid transparent !important;
}
.overflowYScroll {
  overflow-y: scroll;
}

.table_shadow {
  box-shadow: var(--box-shadow);
}
// .nestedtab {
//   box-shadow: var(--box-shadow);
// }
.worflow_modal {
  max-height: 62vh !important;
  overflow-y: scroll !important;
}
.Approver_boxmobile {
  background: #fafafa;
  box-shadow: var(--boxShadowBrand);
}
.Approver_box {
  // max-width: 600px;
  // min-width: 600px;
  background: #d5d5fb;
  box-shadow: var(--boxShadowBrand);
}
.forApprover_contentmobile {
  // max-width: 600px;
  // min-width: 300px;
  box-shadow: var(--boxShadowBrand);
}
.forApprover_content {
  // max-width: 600px;
  // min-width: 600px;
  box-shadow: var(--boxShadowBrand);
}
.btnBottom {
  background: #fefefe;
  position: sticky;
  right: 0px;
  bottom: 0px;
}

// ----------------------------------------------------------table css---------------------------------------

.css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
}

.css-t3ipsp-control:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: #bdc1c6 !important;
}

.sc-fbJfA {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.dlfHg {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.ekxHqF {
  background-color: #c0bff9 !important ;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #d5d5fb4f !important;
}
.eoCEVt {
  border-bottom: 1px solid #0000000d !important;
}
.jWpMhA {
  border-bottom: 1px solid #0000000d !important;
}
.cOhUY {
  border-top: none !important;
}
.eoCEVt:hover {
  background-color: #d5d5fb !important;
}
input[type="checkbox" i]:checked {
  background: var(--brandbluebg);
}

#navbarScroll {
  padding: 0px !important;
}
.progress-bar-css {
  height: 5px;
  background-color: var(--bglightblue) !important ;
  width: 100%;
}

// .progress-bar {
//   background-color: var(--success) !important;
//   border-radius: 20px !important;
// }

.textBlack {
  color: var(--textDark) !important;
  font-weight: 600 !important;
}
.card-update-date {
  color: #313131;
  font-weight: 400;
  letter-spacing: 0.5;
  font-size: 12px;
  background-color: var(--workflowColor);
  border-radius: 3px;
}

.InputApprove {
  width: 100% !important;
  padding: 6px 8px !important;
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  min-height: 38px !important;
}
input:focus-visible {
  outline: none !important;
}
.mobileFilterSortName {
  font-size: 14px !important;
  font-weight: 600;
  color: var(--textDark30) !important;
  cursor: pointer;
}
.applyFilter {
  color: var(--success) !important;
}
.sharebtn {
  border-radius: 0.1rem !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  background: transparent !important;
}
.sharebtndanger {
  border-radius: 0.1rem !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--white) !important;
  background: var(--brandbluebg) !important;
  padding: 6px 12px !important;
}

.updatebtndanger {
  border-radius: 0.1rem !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--white) !important;
  background: var(--brandbluebg) !important;
  padding: 6px 12px !important;
}

.counterparty_btndanger {
  border-radius: 0.1rem !important;
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  background: transparent !important;
  padding: 6px 12px !important;
}
.small {
  font-size: 12px !important;
}

// -------------------------------------------------------------//------------------------------------------------//

.h-menu {
  font-weight: 400;
  font-size: 15px;
  padding: 10px;
  color: gray;
}
.drp {
  z-index: 3000000;
}

@media only screen and (max-width: 600px) {
  // .sidebar {
  //   max-width: 340px !important;
  //   min-width: 340px !important;
  //   height: 100svh;
  //   overflow-y: scroll;
  //   z-index: 10000;
  //   position: absolute;
  //   right: auto;
  //   left: 0;
  // }
}

.logo-css {
  margin-left: 15px;
  font-size: 15px;
  border-top: 1px solid hsla(213, 7%, 76%, 0.239);
  border-bottom: 1px solid hsla(213, 7%, 76%, 0.239);
}

.baslist {
  display: flex;
  color: #4a48c8;
}
.dropdown-css {
  border-radius: 100% !important;
  overflow: hidden;
  max-height: 50px;
  width: 50px;
  border: none !important;
}

// -------------------------------------------------------------------------

.header-css {
  text-decoration: none;
  color: #4a48c8 !important;
  position: relative;
  cursor: pointer;
}

.header-css:hover {
  color: #4a48c8 !important;
  font-weight: 600;
}

.header-css.active {
  color: #4a48c8 !important;
  font-weight: 600 !important;
}

.header-css.active:after {
  width: 100%;
  left: 0;
}
.header-css:focus:after {
  width: 100%;
  left: 0;
}

.header-css:hover:after {
  width: 100%;
  left: 0;
}

.header-css:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -15px;
  margin-bottom: 10px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #4a48c8;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

// ----------------------------------------------------------------------------------
.Home-css {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 15px;
  padding: 5px;
}
.arrow-css {
  display: flex;
  font-size: 20px;
  margin-top: 15px;
  margin-right: 10px;
}

.sign-css {
  display: flex;
  justify-content: end;
  align-items: center;
  color: black;
  font-weight: bold;
}
// .popup-css {
//   border: 1px solid #202020;
//   border-radius: 5px 5px;
// }
.w-206 {
  width: 100% !important;
  height: 15vh !important;
}
.popup-btn-css {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  height: 50px;
}
.btn-css {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: #fefefe;
  background-color: #29e1aa;
  border: 2px solid #29e1aa;
  border-radius: 5px;
  font-weight: bold;
}
.btn-one-css {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  color: #f55252;
  background-color: #fefefe;
  border: 2px solid #f55252;
  border-radius: 5px;
  font-weight: bold;
}

.fname {
  display: flex;
  width: 100%;
  padding-bottom: 100px;
  border: 1px solid #202020;
  border-radius: 5px 5px;
}

.nav-css {
  display: inline-block;
  position: relative;
  color: #4a48c8;
  font-size: 15px;
  cursor: pointer;
}
.nav-css::after {
  content: "";
  position: absolute;
  width: 70px;
  transform: scaleX(0);
  height: 2px;
  background-color: #4a48c8;
  transform-origin: bottom right;
  transition: transform 0.15s ease-out;
}
.nav-css:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
// ....................................profile section............................................................

#avatar-dropdown {
  border: none !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #a8aab7;
}
.dropdown-toggle::after {
  vertical-align: middle;
}

.nav {
  display: flex;
  text-decoration: none;
  color: #4a48c8;
  position: relative;
  font-weight: 600 !important;
  box-shadow: #f1f4f8;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 7px;
  margin-right: 7px;
  // overflow: hidden;
}
.activeProfileNav:hover {
  border-bottom: 2px solid #4a48c8;
}
.field-error {
  color: red;
}

.font {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333f52;
  background-color: #fefefe;
  width: 20px;
  height: 20px;
  box-shadow: 10px 24px 54px rgba(5, 5, 6, 0.2);
  border-radius: 50%;
}
.circle {
  display: flex;
  border: none;
  margin-bottom: 30px;
}
.profile {
  box-shadow: 10px 24px 54px rgb(15 13 35 / 3%) !important;
}
.update-btn {
  display: flex;
  background-color: #2abf86 !important;
  color: white;
  border: 1px solid #f1f4f8;
  font-size: medium;
}
.update-btn:hover {
  border: 1px solid #f1f4f8;
  color: white;
  background-color: #f1f4f8;
}
.close-btn-css {
  display: flex;
  margin-top: 5px;
}
.cbtn {
  border: 1px solid #e15554;
  background-color: white;
  color: #e15554;
}
.cbtn:hover {
  border: 1px solid #e15554;
  color: #e15554;
  background-color: #f1f4f8;
}
.p-header {
  display: flex;
  justify-content: space-between;
}

.profile-username {
  opacity: 1;
  color: rgba(49, 49, 49, 1);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.profile-pic {
  width: 100px;
  max-height: 100px;
  border-radius: 100%;
}

.file-upload {
  display: none;
}

.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  // margin-right: 50px;
}

img {
  max-width: 100%;
  height: auto;
}

.p-image {
  top: 30px;
  // right: 50px;
  left: 30px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

.h5 {
  border: 1px solid black;
}
.varibles_card {
  box-shadow: var(--box-shadow);
}
.Varible_name {
  color: var(--brandbluebg);
}
.variable_format {
  color: var(--textDark30);
}

.add-step-btn {
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  border-radius: 1px !important;
}
// .modal-footer {
//   border: none !important;
// }
.add-step-btn:hover {
  border: 1px solid var(--brandbluebg) !important;
  color: var(--brandbluebg) !important;
  border-radius: 1px !important;
}
.input_label {
  font-weight: 600;
  margin-bottom: 6px;
}
.organizationName {
  font-size: 16px;
}
.logoSide {
  max-width: 220px;
  min-width: 220px;
}

.remove {
  color: var(--danger);
  cursor: pointer;
  font-size: 12px;
}

.rounded-circle-css {
  border: none !important;
}

.close-icon-css {
  color: #e15554;
  font-size: 20px;
}

// ---------------------------------------------------------------------------------------headerprofile----------------------------

.memberimageshape {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.memberimageshape img {
  object-fit: cover;
  border-radius: 50%;
}

.dropdown_menu {
  position: absolute;
  top: 50px;
  right: 0px;
  background: #fefefe;
  border-radius: 5px;
  width: 250px;
  box-shadow: var(--box-shadow);
  z-index: 999;
}
.memberimageshapeNav .dropdown-toggle::after {
  display: none !important;
}

.dropdown_menu_profile {
  position: absolute;
  // top: 50px;
  right: 0px;
  background: #fefefe;
  border-radius: 5px;
  width: 250px;
  box-shadow: var(--box-shadow);
  z-index: 999;
}

ul {
  padding: 0;
  // list-style-type: none;
}

.dropdown_menu ul li {
  padding: 10px 0;
  border-bottom: 1px solid #0000000d;
}

.dropdown_menu ul li a {
  color: var(--textDark50);
  font-weight: 600;
}

.dropdown_menu ul li a:hover {
  color: var(--brandbluebg);
  cursor: pointer;
}

// .ulforMobile li a {
//   color: var(--title-white);
//   font-weight: 500;
// }

// .ulforMobile {
//   border-top: 1px solid #bdc1c63d;
// }

// .mobileBorder {
//   border-top: 1px solid #bdc1c63d;
// }

.langpadding {
  padding-inline-start: 0.7rem;
}

.dropdown_menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: 500ms ease;
}

.dropdown_menu.inactive {
  opacity: 1;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

.viewprofiledropdown {
  padding-inline-start: 0.7rem;
}
.template_name {
  font-size: 14px;
}
.LastEdited {
  font-size: 14px;
}
.sidebar_text {
  font-size: 14px !important;
}

.eye-icon-css {
  position: absolute;
  right: 15px;
  top: 35px;
  cursor: pointer;
}
.Actions_title {
  background: transparent !important;
  border: none !important;
  padding: 0% !important;
}
.Actions .dropdown-menu.show {
  right: 20px !important;
}
.Action_download {
  color: var(--textDark);
}
.Action_download:hover {
  color: var(--text-black);
}

.overview_title {
  font-size: 16px;
}
.nav-item {
  margin: 0px 2px !important;
}

.counterparty_error {
  color: #e15554;
}
.icon_hover:hover {
  color: var(--brandbluebg) !important;
  font-size: 24 px;
}

.waterMark {
  position: absolute;
  // left: 15%;
  // top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.invite {
  font-size: 12px;
}
.userEmail {
  font-size: 14px;
}
.btn-close {
  z-index: 1 !important;
}

.slate-ToolbarButton-active {
  color: rgb(33, 16, 181) !important;
}

.modal-header-bottom {
  border-bottom: none !important;
}

.modal-header-bottom-hirerchy {
  border-bottom: none !important;
  margin-bottom: -45px;
}

.modal-title-name {
  border-bottom: 2px solid;
}

.add_variables_btn {
  margin-right: 40px;
}
.css-13cymwt-control {
  border-radius: 1px !important;
  border-color: #dee2e6 !important;
}

.css-t3ipsp-control {
  border-radius: 1px !important;
  border-color: #dee2e6 !important;
}

.variable_Select .css-13cymwt-control {
  height: 39px !important;
  border-color: #dee2e6 !important;
}

.btn_margin {
  margin-right: 12px;
}

// ----------------------------------------------workflow part new----------------------------------
.sub_appover_para {
  font-size: 12px;
  color: white;
}

.linemiddle {
  width: 1px;
  top: 50%;
  height: 20px;
  background-color: #c5d0de;
}
.Appover_down_box {
  border: 1px solid var(--workflowColor);
}
.border_right {
  border-right: 1px solid var(--workflowColor);
}
.workflow_btn {
  border: 1px solid var(--workflowColor);
}
.workflow_btn:hover {
  background-color: var(--workflowColor);
}
.bgpink {
  background-color: var(--workflowColor);
}
.Approveractive-content {
  display: block;
}
.content {
  display: none;
}
.output_box {
  border: 1px solid var(--workflowColor);
}
.Approver_box_new {
  background-color: var(--workflowColor);
  box-shadow: var(--box-shadow);
}
.ApproverFullBox {
  border: 1px solid var(--workflowColor);
}

.date_picker_css .react-date-picker__wrapper {
  width: 450px;
  height: 40px;
  border: 1px solid lightgray !important;
}
.variable_input .react-datepicker-wrapper {
  width: 100% !important;
}

.react-date-picker__wrapper {
  border: 1px solid lightgray !important;
  min-height: 38px !important;
}
.react-calendar__tile:disabled {
  background: transparent !important;
}
.react-calendar__tile--now {
  background: var(--BrandBlue) !important;
  color: var(--white);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #d5d5fb !important;
  color: black;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #d5d5fb !important;
  color: black;
}
.react-calendar__tile--active {
  background: #d5d5fb !important;
  color: black;
}
.react-date-picker__calendar .react-calendar {
  border: 0px;
  padding: 5px;
  box-shadow: var(--box-shadow);
  margin-top: 10px;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__navigation button:disabled {
  background: #d5d5fb !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: #d5d5fb !important;
}
.react-date-picker__inputGroup__input {
  color: var(--textDark) !important;
}
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  color: var(--textDark) !important;
}

.date_picker_css_filter .react-date-picker__wrapper {
  width: 270px;
  height: 40px;
  border: 1px solid lightgray !important;
}
.react-date-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__calendar-button__icon {
  stroke: lightgray !important;
}

// -----------------------------------------------------------------------roles-----------------------------

.table_class {
  position: relative;
}

.table_class h6 {
  color: var(--text-color);
}

.table_class td {
  color: #202020 !important;
  font-weight: 600;
}

.table_white {
  background: #fefefe !important;
  border-radius: 5px !important;
}

.table_white_head {
  // background: #6b5ca42e !important;
  background: #4a48c8e8;
  color: var(--white);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  // border-bottom-width: 0 !important;
  // border-left: 0 !important;
  box-shadow: inset 4 0 0 9999px var(--bs-table-accent-bg);
}

.table {
  // border-collapse: separate;
  border-spacing: 0 1.5rem;
  overflow: scroll;
  border-radius: 15px !important;
}

th,
td {
  vertical-align: middle !important;
}

// --------------------------------------------------submenu-------------------------------

.sidebar-item {
  display: block;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-title-mobile .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title-mobile .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  height: 0;
  overflow: hidden;
}
.sidebar-title:hover {
  background-color: #5957dfbf !important;
}
.sidebar-content:hover {
  background-color: #5957dfbf !important;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
  transition: 10s ease-in-out;
}

// -------------------------------------------Editor toolbar-------------------
.light-btn {
  background-color: rgb(255, 255, 255) !important;
  padding: 0px !important;
  top: -0px;
}
.colorPicker-Btn {
  border-radius: 25px !important;
}

.btn-mark-editor {
  color: #1c2024;
  background-color: white !important;
  border-color: white !important;
}

#ul-editor {
  padding-left: 40px !important;
}

[data-slate-placeholder] {
  position: absolute !important;
  pointer-events: none;
  min-width: 130px !important;
  display: block;
  display: flex !important;
  opacity: 0.333;
  user-select: none;
  text-decoration: none;
}

// -----------------------------------------------------------------------------
.accordion-button {
  background-color: transparent !important;
}

.inner-sidebar {
  margin-left: 35px;
}

.recent_folder {
  background-color: #f1f1f5 !important;
}

.folder_update_date {
  background-color: #f1f1f5;
  border: 0.5px solid #333f52;
}
.no_of_folders {
  font-size: 20px !important;
  font-weight: 600;
}
.dotCorner {
  right: -32px;
  top: -15px;
}

.dot_corner_version {
  right: 13px;
  top: 16px;
}

.file_no_data_found {
  min-height: 35vh !important;
}

.list_group_class {
  background-color: lightgray;
  border-top-left-radius: none;
  border-top-right-radius: none;
  padding: 6px !important;
}

.accordion-body {
  padding: 0px;
}

.move_folder_list {
  background-color: lightgray;
}

.move_click {
  color: "#5957dfbf";
  cursor: pointer;
}
.right_box_version {
  min-width: 350px;
  height: 94vh;
  box-shadow: var(--box-shadow);
}
.show_changes {
  position: fixed;
  bottom: 0;
}
.show_changes_checkbox input[type="checkbox" i] {
  background: var(--brandbluebg);
  width: 20px !important;
  height: 20px !important;
}
.yesterday {
  font-size: 10px;
}

.tab .checkbox_version {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  position: relative;
  padding: 1em;
  color: #000;
  cursor: pointer;
  width: 100%;
}
.tab-label:hover {
  background: var(--colorHover);
}
.tab-label::before {
  // content: "❯";
  content: url("../assets/arrow-right.png");
  position: absolute;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #000;
  font-weight: 300;
  background: white;
  transition: all 0.35s;
}
.tab-content h6 {
  font-size: 14px;
  font-weight: 300;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab input:checked + .tab-label {
  background: var(--colorHover);
}
.tab input:checked + .tab-label::before {
  margin-right: 5px;
  transform: rotate(90deg);
}
.tab input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
.dotColor {
  border-radius: 50%;
  background: var(--success);
}
.dotVersion {
  right: 0px;
  top: 5px;
  z-index: 1;
}
.current_version {
  font-size: 12px;
}

.active_version {
  background-color: #e8f0fe;
}

// ...........................date picker ...............................................
.calendarElement {
  position: absolute;
  top: 40px;
  right: 0%;
  background: var(--title-white);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.5);
}

.rmdp-input {
  // padding: 15px 20px !important;
  // width: 240px;
}

.rmdp-container {
  width: 100% !important;
}

.dateRangeformobile1 {
  width: 100% !important;
}

.dateRangeformobile {
  width: 100% !important;
}

.dateRangeformobile1 .dateRangeformobile .rmdp-input {
  width: 100% !important;
}

.rmdp-week-day {
  color: "purple" !important;
}

.rmdp-arrow {
  border: solid "purple" !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid "purple" !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-range {
  background-color: "purple" !important;
  box-shadow: var(--box-shadow) !important;
  color: var(--title-white) !important;
  // border-radius: 50% !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--datePicker) !important;
  color: var(--title-white) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: var(--datePicker) !important;
  color: var(--title-white) !important;
}

.rmdp-header-values {
  color: "purple" !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: "purple" !important;
}

.rmdp-container {
  grid-area: 1/1/2/3 !important;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  border: 1px #dee2e6 !important;
  margin-top: 18px;
}

.transfer_ownership {
  color: gray;
}

.transfer {
  color: red;
}

.react-calendar__month-view__weekdays__weekday {
  color: gray;
  margin-bottom: none;
  border-bottom: none;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}
.abbr {
  text-decoration: none !important;
}

.org_name_stamp {
  width: 100% !important;
  height: 38px !important;
}

.sec_org_name_stamp {
  border: 1px solid lightgray;
}

.add_coll_doc_header {
  cursor: pointer;
}

// ---------------------------------------------------editor------------------------------
// .css-gnx5xk {
//   padding: 8px 7px 6px;
//   position: absolute;
//   top: 250px !important;
//   left: 100px !important;
//   margin-top: -6px;
//   //  opacity: 0;
//    background-color: #e7ecec;
//   border-radius: 4px;
//   -webkit-transition: opacity 0.75s;
//   transition: opacity 0.75s;
// }
.caret-highlight {
  position: absolute !important;
}
// ------------------------------------------------Document Header ----------------------------
#custom-switch {
  background-color: #fff !important;
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: #304f6d !important;
  border-color: #304f6d !important;
}

.checkp input[type="checkbox"] {
  position: relative;
  width: 38px;
  height: 16px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px var(--colorHover);
  transition: 0.7s;
}

.checkp input:checked[type="checkbox"] {
  background: #03a9f4;
}

.checkp input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 20px;
  top: -1px;
  left: 0;
  background: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.checkp input:checked[type="checkbox"]:before {
  left: 40px;
}

canvas {
  width: 400px;
  height: 300px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.commentsPopup {
  position: absolute;
  top: 10px;
  left: 20%;
}
.kd-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.disabledCoordinate {
  opacity: 0.5; /* You can adjust the opacity to make it visually appear disabled */
  pointer-events: none;
}

.e_sign {
  border: "2px solid gray";
}

.pdf_form {
  border: 1px solid lightgray;
  padding: 15px;
}

.new_coordinate {
  cursor: pointer;
  color: #2a27ed;
  font-weight: 700;
}

.signature-navbar {
  margin-bottom: -40px;
  margin-left: -8px;
}

.signature_active_navbar {
  color: #625858;
  border-bottom: 2px solid #625858;
}

.drag_and_drop_signature {
  // width: 450px;
  height: 180px;
  padding: 10px;
  border: 1px solid #e9ecef;
  gap: 10px;
  background-color: #f1f1f5;
}

.signature_name {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

.signature_type {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.signature_edit_remove_btn {
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: gray;
  cursor: pointer;
  border-bottom: 2px solid lightgray;
}

.form-wizard .form-wizard-item {
  width: 100%;
}

.form-wizard .form-wizard-item hr {
  width: 100%;
  border: 2px solid var(--BrandBlue) !important;
  color: var(--BrandBlue);
}

.form-wizard .form-wizard-item.active hr {
  opacity: 1;
}
.wizardTitle {
  display: flex;
  justify-content: center;
}

// ..............................................dashboard......................................
.dashboard_header {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
}

.nasted_dashboard_tab {
  // color: #4A48C8;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 0px !important;
}

#chartdiv {
  width: 100%;
  height: 400px;
}

#chartdiv1 {
  width: 100%;
  height: 400px;
}
#chartdiv3 {
  width: 100%;
  height: 400px;
}

.dashboard_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #333f52;
}

#ZoomableBubbleChart {
  width: 100%;
  height: 400px;
}

#LineChart {
  width: 100%;
  height: 400px;
}

#daysINNegotiation {
  width: 100%;
  height: 400px;
}

#ContractCycleTime {
  width: 100%;
  height: 400px;
}

#TurnaroundTimeForApprovals {
  width: 100%;
  height: 400px;
}

#TurnaroundTimeForSIgned {
  width: 100%;
  height: 400px;
}

#TurnaroundTimeForCompletedRenewals {
  width: 100%;
  height: 400px;
}

#ContractSignedByEachTeam {
  width: 100%;
  height: 400px;
}

#ContractRequiringApproval {
  width: 100%;
  height: 400px;
}

#ContractINitiatedByEachTeam {
  width: 100%;
  height: 400px;
}

#CounterpartyByContractAmount {
  width: 100%;
  height: 650px;
}
.fliedIcon {
  min-height: 30px;
  width: 30px;
  background-color: var(--workflowColor);
}
.addHierarchy {
  position: absolute;
  left: -17px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--textDark30);
  border-radius: 50%;
}
.addHierarchyIcon {
  position: absolute;
  left: -17px;
  width: 40px;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 50%;
  cursor: pointer;
}
.horizontalHierarchyLine {
  position: absolute;
  left: 14px;
  top: 29px;
  border-left: 2px solid var(--border);
}

.list_questions .accordion .accordion-item button {
  box-shadow: none !important;
  color: var(--textDark);
}
.list_questions {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
}

.list_questions .accordion .accordion-item button::after {
  background: none;
}
.accordion-button::after {
  background-image: none !important;
  display: inline-block !important;
  margin-left: 0.255em !important;
  vertical-align: 0.255em !important;
  content: "" !important;
  border-top: 0.4em solid var(--textDark30) !important;
  border-right: 0.4em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0.4em solid transparent !important;
  width: 0 !important;
  height: 0 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--textBlack) !important;
}

// .hierarchyBody > details {
//   width: 100%;
//   margin-bottom: 10px;
// }
// .hierarchyBody > details summary {
//   padding: 10px;
//   background-color: var(--white);
//   border-left: 4px solid var(--textDark30);
//   box-shadow: var(--box-shadow);
// }
// .hierarchyBody > details summary::-webkit-details-marker {
//   margin-right: 14px;
// }
// .hierarchyBody > details > details {
//   margin: 20px 0;
//   margin-left: 40px;
//   position: relative;
// }
// .hierarchyBody > details > details::after {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: -70px;
//   width: 1px;
//   height: calc(36px + 24px);
//   background-color: #c7d3df;
// }
// .hierarchyBody > details > details:last-child::after {
//   height: calc(36px / 2 + 5px);
// }
// .hierarchyBody > details > details::before {
//   content: "";
//   position: absolute;
//   top: calc(36px / 2);
//   left: -67px;
//   width: 65px;
//   height: 1px;
//   background-color: #c7d3df;
// }
// .hierarchyBody > details > details[open]:not(:last-child)::after {
//   height: calc(100% + 20px);
// }
// .hierarchyBody > details > details summary {
//   padding: 10px;
//   background-color: var(--white);
//   border-left: 4px solid var(--textDark30);
//   box-shadow: var(--box-shadow);
// }
// .hierarchyBody > details > details summary::-webkit-details-marker {
//   margin-right: 14px;
// }
// .hierarchyBody > details > details > details {
//   margin: 5px 0;
//   margin-left: 40px;
//   position: relative;
// }
// .hierarchyBody > details > details > details::after {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: -10px;
//   width: 1px;
//   height: calc(28px + 10px);
//   background-color: #c7d3df;
// }
// .hierarchyBody > details > details > details:last-child::after {
//   height: calc(28px / 2 + 5px);
// }
// .hierarchyBody > details > details > details::before {
//   content: "";
//   position: absolute;
//   top: calc(28px / 2);
//   left: -10px;
//   width: 8px;
//   height: 1px;
//   background-color: #c7d3df;
// }
// .hierarchyBody > details > details > details[open]:not(:last-child)::after {
//   height: calc(100% + 5px);
// }
// .hierarchyBody > details > details > details summary {
//   margin: 20px 0;
//   padding: 5px 10px;
//   background-color: var(--white);
//   border-left: 4px solid var(--textDark30);
//   box-shadow: var(--box-shadow);
//   position: relative;
// }
// .hierarchyBody > details > details > details summary span {
//   color: #041320;
// }

// .hierarchyBody ul {
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
// }

// .hierarchyBody li {
//   margin: 20px 0;
//   padding: 10px;
//   background-color: var(--white);
//   border-left: 4px solid var(--textDark30);
//   box-shadow: var(--box-shadow);
//   position: relative;
//   margin-left: 80px;
//   position: relative;
// }
// .hierarchyBody li span {
//   color: #041320;
// }
// .hierarchyBody li::after {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: -10px;
//   width: 1px;
//   height: calc(28px + 10px);
//   background-color: #c7d3df;
// }
// .hierarchyBody li:last-child::after {
//   height: calc(28px / 2 + 5px);
// }
// .hierarchyBody li::before {
//   content: "";
//   position: absolute;
//   top: calc(28px / 2);
//   left: -67px;
//   width: 65px;
//   height: 1px;
//   background-color: #c7d3df;
// }
// .hierarchyBody li[open]:not(:last-child)::after {
//   height: calc(100% + 5px);
// }

.hierarchyBodyAccordion {
  position: absolute;
  z-index: 11;
  background: #fefefe;
  width: 100%;
  box-shadow: var(--box-shadow);
}
.hierarchyBody summary::marker {
  display: none !important;
}
.hierarchyBody summary::-webkit-details-marker {
  display: none !important;
}
// ul,
summary {
  list-style-type: none;
}
summary::marker {
  color: #fff;
  z-index: -5;
}

// ------------------------------------------------------------------------------------------------------------------------new treee

.treeview .btn-default {
  border-color: #e3e5ef;
}
.treeview .btn-default:hover {
  background-color: transparent;
  color: transparent;
}
.treeview ul {
  list-style: none;
  padding-left: 32px;
}
.treeview ul li {
  padding: 40px 0px 0px 35px;
  position: relative;
}

.treeview ul li:before {
  content: "";
  position: absolute;
  top: -28px;
  left: -31px;
  border-left: 2px dashed var(--border);
  width: 1px;
  height: 100%;
}
.treeview ul li:after {
  content: "";
  position: absolute;
  border-top: 2px dashed var(--border);
  top: 87px;
  left: -30px;
  width: 46px;
}
.treeview ul li:last-child:before {
  top: -25px;
  height: 108px;
}
.treeview > ul > li:after,
.treeview > ul > li:last-child:before {
  content: unset;
}
.treeview > ul > li:before {
  top: 90px;
  left: 36px;
}
.treeview > ul > li:not(:last-child) > ul > li:before {
  content: unset;
}
.treeview > ul > li > .treeview__level:before {
  height: 60px;
  width: 60px;
  top: 17.5px;
  background-color: #54a6d9;
  border: 7.5px solid #d5e9f6;
  font-size: 22px;
  display: none;
}
.treeview > ul > li > ul {
  padding-left: 34px;
}
.treeview__level {
  padding: 7px;
  padding-left: 42.5px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 700;
  // border: 1px solid #e3e5ef;
  position: relative;
  // z-index: 1;
}
.treeview__level:before {
  content: attr(data-level);
  position: absolute;
  left: -27.5px;
  top: 20.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 7.5px solid #eef6d5;
  background-color: #bada55;
  color: #fff;
  font-size: 20px;
  z-index: 1;
  display: none;
}
.treeview__level-btns {
  margin-left: 15px;
  display: inline-block;
  position: relative;
}
.treeview__level .level-same,
.treeview__level .level-sub {
  position: absolute;
  display: none;
  transition: opacity 250ms cubic-bezier(0.7, 0, 0.3, 1);
}
.treeview__level .level-same.in,
.treeview__level .level-sub.in {
  display: block;
}
.treeview__level .level-same.in .btn-default,
.treeview__level .level-sub.in .btn-default {
  background-color: #faeaea;
  color: #da5555;
}
.treeview__level .level-same {
  top: 0;
  left: 45px;
}
.treeview__level .level-sub {
  top: 42px;
  left: 0px;
}
.treeview__level .level-remove {
  display: none;
}
.treeview__level.selected {
  background-color: #f9f9fb;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
}
.treeview__level.selected .level-remove {
  display: inline-block;
}
.treeview__level.selected .level-add {
  display: none;
}
.treeview__level.selected .level-same,
.treeview__level.selected .level-sub {
  display: none;
}
.treeview .level-title {
  cursor: pointer;
  user-select: none;
}
.treeview .level-title:hover {
  text-decoration: underline;
}
.treeview--mapview ul {
  justify-content: center;
  display: flex;
}
.treeview--mapview ul li:before {
  content: unset;
}
.treeview--mapview ul li:after {
  content: unset;
}

.active_hierarchy {
  background-color: lightgray;
}
.editorPopup {
  position: absolute;
  top: 0px;
  left: 65%;
}
.editorPopup .card {
  left: 225px !important;
}
.editorPopupSuggestion {
  position: absolute;
  top: 0px;
  left: 65%;
}

.editorPopupSuggestion .card {
  left: 225px !important;
}

.variablesModalTable td {
  border-color: rgb(255, 255, 255) !important;
}
.commentSndBtn {
  // position: absolute;
  // bottom: 21px;
  // right: 10px;
  width: 26px;
  height: 26px;
  background-color: #fefefe;
}
.commentBoxSnd {
  background-color: #fefefe;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.Replies {
  color: var(--brandbluebg);
  font-size: 12px;
  cursor: pointer;
}
.commentbage {
  background-color: #d5d5fb4a;
  color: var(--brandbluebg);
  font-size: 11px;
}
.changeSignatoryField:hover {
  background-color: #fafafa;
}

.changeSignatoryField.active {
  background-color: #fafafa;
}
.cross_active_esign {
  cursor: pointer;
}
.notificationBox {
  background-color: #fefefe;
  // box-shadow: var(--box-shadow);
  border: 1px solid #ced4da57;
}
.deleteBtnFornoti {
  position: absolute;
  right: 5px;
  top: 5px;
}

.notiBox {
  background: #fefefe;
  min-width: 400px;
  max-height: 55vh;
  overflow-y: scroll;
  padding-bottom: 75px;
}

.notiBoxheight {
  max-height: 80vh !important;
}
.notficationCount {
  position: absolute;
  right: 4px;
  top: 2px;

  width: 8px;
  height: 8px;
}

.is_active_notification {
  background: var(--danger);
}
.signatureChooseFile {
  position: absolute;
  top: 0px;
  font-size: 120px;
  opacity: 0;
}

.variablesModalTable td {
  border-color: rgb(255, 255, 255) !important;
}
.disabled_stamp,
.disabled_stamp:hover {
  color: #bcbcde !important;
  border: none;
}
.signBox:hover {
  background: var(--colorHover);
}

.signBox.active {
  background: var(--colorHover);
}
.cross_active_esign {
  position: absolute;
  right: -2px;
  top: -2px;
}

// ---------------------------------------------document header responsive-----------------------

.addCollab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--workflowColor);
  box-shadow: var(--box-shadow);
}
.react-tooltip {
  z-index: 1;
}

.dropdownHeader {
  display: inline-block;
  position: relative;
}

.dropdownHeader span {
  transition: 0.3s;
  cursor: pointer;
}

.dropdownHeader:hover {
  z-index: 1;
}
.dropdownHeader > div {
  background-color: #fff;
  z-index: 1;
  visibility: hidden;
  position: absolute;
  min-width: 170px;
  opacity: 0;
  transition: 0.3s;
  left: -160px;
  top: 0px;
  box-shadow: var(--box-shadow);
}
.dropdownHeader .submenu {
  position: relative;
}
.dropdownHeader .submenu > div {
  background-color: #fff;
  visibility: hidden;
  position: absolute;
  left: -95px;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  z-index: 1;
  box-shadow: var(--box-shadow);
}

.dropdownHeader .submenu:hover > div {
  visibility: visible;
  opacity: 1;
}

.dropdownHeader:hover > div {
  visibility: visible;
  opacity: 1;
  left: -160px;
  top: 0px;
  z-index: 1;
}

.dropdownHeader a {
  display: block;
  font-weight: 500;
  text-decoration: none;
  padding: 8px;
  padding-left: 12px;
  color: #000;
  transition: 0.1s;
  white-space: nowrap;
}
.input-group-text {
  padding: 0px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}
.input-group-text .btn {
  padding: 0.3rem !important;
}
.editorTabs {
  max-width: 94%;
  display: flex;
  max-height: 45px !important;
  align-items: center;
  // overflow-y: scroll;
  overflow-y: auto;
}
@media screen and (max-width: 1700px) {
  .editorTabs {
    max-width: 1547px;
  }
}
@media screen and (max-width: 1600px) {
  .editorTabs {
    max-width: 1480px;
  }
}
@media screen and (max-width: 1500px) {
  .editorTabs {
    max-width: 1380px;
  }
}
@media screen and (max-width: 1400px) {
  .editorTabs {
    max-width: 1280px;
  }
}
@media screen and (max-width: 1300px) {
  .editorTabs {
    max-width: 1180px;
  }
}
@media screen and (max-width: 1280px) {
  .editorTabs {
    max-width: 1010px;
  }
}
@media screen and (max-width: 1024px) {
  .editorTabs {
    max-width: 940px;
  }
}
@media screen and (max-width: 912px) {
  .editorTabs {
    max-width: 840px;
  }
}
@media screen and (max-width: 768px) {
  .editorTabs {
    max-width: 700px;
  }
}
.editorTabs button {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px 10px !important;
}
.editorTabs button:hover {
  background-color: var(--white) !important;
  border-radius: 5px !important;
  border-color: transparent !important;
}
.editorTabs button:focus {
  background-color: var(--white) !important;
  border-radius: 5px !important;
  border-color: transparent !important;
}

.fontSizeHover .form-select:focus {
  border-color: transparent !important;
  box-shadow: none !important; /* Optional: Remove the focus box-shadow */
  outline: none !important;
}

.editorSelect {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.editorSelect:focus {
  background-color: var(--white) !important;
  box-shadow: var(--box-shadow);
}
.editorSelect:hover {
  background-color: var(--white) !important;
  box-shadow: var(--box-shadow);
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-btn.ant-btn-lg {
  height: 42px !important;
}
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-btn.ant-btn-lg.ant-btn-icon-only {
  // width: min-content !important;
  width: 40px !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: var(--textDark) !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-btn-default {
  border: 0px !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-btn-default {
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-btn .ant-btn-icon {
  cursor: pointer !important;
}
.ant-btn label {
  cursor: pointer !important;
}
.mentionsInput {
  min-height: 62px !important;
}
// .scrollIcon {
//   position: absolute;
//   right: 20px;
//   top: 15px;
// }

// ...............loader........................
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #4a48c8;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#dropdown-button-drop-end {
  font-weight: 400 !important;
}
#dropdown-button-drop-end::after {
  display: none !important;
}
#document-layout header .nav-pills .nav-link:hover {
  background-color: #d5d5fb4f !important;
}
#document-layout header .nav-pills .nav-link:active {
  background-color: #d5d5fb4f !important;
}
#document-layout header .nav-pills .show > .nav-link {
  background-color: #d5d5fb4f !important;
}
.editorSearch {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 560px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
}
.editorSearchSmall {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  position: absolute;
  top: 20px;
  right: 10%;
  width: 400px;
}
.fromCheckSearch {
  min-height: 0px !important;
}
.editorSearchbtn {
  width: max-content;
  background: #f8f9fa !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #202124 !important;
  padding: 8px 20px !important;
  font-size: 16px !important;
  opacity: 0.62 !important;
  box-shadow: var(--box-shadow);
}
.editorreplacebtn {
  width: max-content;
  background: #fefefe !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #3c4043 !important;
  padding: 8px 20px !important;
  font-size: 16px !important;
  opacity: 0.38 !important;
  box-shadow: var(--box-shadow);
}
.findCount {
  position: absolute;
  right: 8px;
  top: 8px;
  background: #fefefe !important;
}
.mentionsInput {
  min-height: 35px !important;
  border: 0px !important;
  outline: 0 !important;
}
.comment-input:focus-visible {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #d5d5fb !important;
  outline: 0 !important;
  box-shadow: 0 0px 9px #d5d5fb !important;
}
.addcolor {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}
.editorDropdown {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}
.smallNone small {
  display: none !important;
}
.errorMsg {
  position: absolute;
  left: 0px;
  top: 37px;
}

.dropdownsort {
  position: absolute;
  top: 45px;
  left: 25px;
  background: #fefefe;
  border-radius: 5px;
  padding: 10px 20px;
  width: 170px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.dropdownsort.active {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.dropdownsort.inactive {
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
}

.basic-multi-select.contract_value {
  text-align: initial;
  width: 90px;
}

.react-draggable.react-draggable-dragged {
  cursor: move;
}
.__suggestions {
  top: 14px !important;
  background-color: #fafafa;
  box-shadow: var(--box-shadow);
}

.loaderBtn {
  width: 48px;
  height: 48px;
  border: 3px dotted #fff;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.no-data-found.move_to_folder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}
.inputToolbarApperence input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.inputToolbarApperence input {
  width: 25px !important;
}
.input-file-wrap {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #fefefe !important;
  color: var(--textDark30) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 20px;
  padding-right: 14px;
  height: 40px;
  box-shadow: var(--box-shadow);
}

.input-file-wrap-version {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #fefefe !important;
  color: var(--textDark30) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 20px;
  padding-right: 14px;
  height: 40px;
}
.uploadContractFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  padding-left: 240px;
  margin-right: -240px;
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  .memberlist {
    padding: 0px 5px;
    // margin: 0px;
  }
}
// .progress-bar {
//   width: 100%;
//   height: 20px;
//   background-color: #ccc;
//   border: 1px solid #999;
//   position: relative;
// }

// .progress {
//   height: 100%;
//   background-color: #007bff;
//   transition: width 0.3s ease; /* Add a transition for smooth animation */
// }

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir="rtl"] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}
pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}

.rdt_TableRow {
  justify-content: flex-start !important;
}
.rdt_TableHeadRow {
  justify-content: flex-start !important;
}
.rdt_TableRow {
  margin-bottom: 5px;
  margin-top: 5px;
}

.yt-e-table-wrap td {
  word-break: unset !important;
  overflow: visible !important;
}

.rdt_TableRow {
  margin-bottom: 5px;
  margin-top: 5px;
}

// .marginTopPEditor {
//   margin-top: -17px !important;
// }

.spinner {
  animation: spin infinite 3s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.movesc {
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.movesc:hover + .hovesection {
  display: block !important;
  background-color: red;
}
.movesc:hover {
  background-color: rgb(216, 216, 216);
}

.hide {
  display: none;
  position: absolute;
  right: 35px;
}

.hide_folder {
  position: absolute;
  right: 35px;
  top: 3px;
  cursor: pointer;
  color: var(--BrandBlue);
}

.myDIV:hover + .hide {
  display: block;
  cursor: pointer;
}
.myDIV {
  width: 456px;
}
.myDIV span {
  cursor: pointer;
}

.orderedList-editor ol {
  counter-reset: item;
  padding-left: 20px;
}
.orderedList-editor ol li {
  display: block;
}
.orderedList-editor ol li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.list_style_none {
  list-style: none;
}
.file_name {
  color: red;
  font-weight: bold;
}

.no_variable {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-left: 50px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: lightgray !important;
  background-image: url("");
}

// .unorderedList-editor ul { counter-reset: item; padding-left: 70px; }
// .unorderedList-editor ul {  list-style-type: 'circle'; }
// .unorderedList-editor  ul li:before { content: counters(item, ".") ". "; counter-increment: item }
.modaldialog {
  max-width: 100% !important;
}
.imageBtnModal {
  padding: 2px 6px 12px 8px;
}
.imageBtnModal svg {
  margin-top: 5px;
}

.suggestionStikeThrough {
  /* color: #06a306; */
  position: relative;
  text-decoration: none;
}
.suggestionStikeThrough::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 100%;
  height: 1px;
  background-color: green;
  transform: scaleY(2);
}
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

// Table resizing border
#tableContainer {
  overflow-x: auto;
}
#resizableTable {
  border-collapse: collapse;
  user-select: none;
}

.pBottomMargin p {
  margin-bottom: 1px !important;
}

.wrapTextList span {
  text-wrap: balance;
}

.nonSelectable {
  user-select: none;
  -moz-user-select: none; /* For Firefox */
  -webkit-user-select: none; /* For Chrome, Safari, and Opera */
  -ms-user-select: none; /* For IE/Edge */
}