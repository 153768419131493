#auth-layout {
  height: 100svh;
  .bgimage {
    position: relative;
    height: 100%;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .border_line {
    border: 2px solid #fafafa;
    border-radius: 2px;
    width: 25%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .opacity-70 {
    opacity: 70%;
  }
}
